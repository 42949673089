<template>
  <div id="messenger">
    <div v-if="breakpoint.xsOnly || breakpoint.smOnly">
      <v-tabs
        v-show="!g_messenger.selected_user && g_chat_bulk_id.length == 0"
        v-model="tab"
        @change="tabChange"
      >
        <v-tab v-show="g_user.role_type != 'ADMIN' || g_user.role_type == null">
          <v-badge v-if="g_has_message.admin" dot color="primary">
            Admin
          </v-badge>
          <span v-else>Admin</span>
        </v-tab>
        <v-tab>
          <v-badge v-if="g_has_message.teacher" dot color="primary">
            {{ $t("app.teacher") }}
          </v-badge>
          <span v-else>{{ $t("app.teacher") }}</span>
        </v-tab>
        <v-tab v-show="g_user.role_type != 'STUDENT'">
          <v-badge v-if="g_has_message.student" dot color="primary">
            {{ $t("app.student") }}
          </v-badge>
          <span v-else>{{ $t("app.student") }}</span>
        </v-tab>
      </v-tabs>
      <v-row no-gutters>
        <v-col v-show="showMessengerList" cols="12" sm="12" md="3">
          <MessengerList :tab="tab" :getChat="getChat" />
        </v-col>
        <v-col v-show="showMessengerChatBox" cols="12" sm="12" md="9">
          <MessengerChatBox
            :tab="tab"
            :getChat="getChat"
            :loadingGetChat="loadingChat"
          />
        </v-col>
      </v-row>
    </div>
    <div v-else class="desktop-view">
      <v-tabs v-model="tab" class="mb-2" @change="tabChange">
        <v-tab v-show="g_user.role_type != 'ADMIN' || g_user.role_type == null">
          <v-badge v-if="g_has_message.admin" dot color="primary">
            Admin
          </v-badge>
          <span v-else>Admin</span>
        </v-tab>
        <v-tab>
          <v-badge v-if="g_has_message.teacher" dot color="primary">
            {{ $t("app.teacher") }}
          </v-badge>
          <span v-else>{{ $t("app.teacher") }}</span>
        </v-tab>
        <v-tab v-show="g_user.role_type != 'STUDENT'">
          <v-badge v-if="g_has_message.student" dot color="primary">
            {{ $t("app.student") }}
          </v-badge>
          <span v-else>{{ $t("app.student") }}</span>
        </v-tab>
      </v-tabs>
      <v-row no-gutters>
        <v-col cols="12" sm="12" md="3">
          <MessengerList :tab="tab" :getChat="getChat" />
        </v-col>
        <v-col cols="12" sm="12" md="9">
          <MessengerChatBox
            :tab="tab"
            :getChat="getChat"
            :loadingGetChat="loadingChat"
          />
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import MessengerChatBox from "./MessengerChatBox";
import MessengerList from "./MessengerList";
import { mapGetters } from "vuex";
import store from "@/store";

export default {
  components: {
    MessengerChatBox,
    MessengerList
  },
  data() {
    return {
      tab: null,
      openPanelStudent: null,
      groupChatId: [],
      loadingChat: false
    };
  },
  computed: {
    ...mapGetters([
      "g_darkmode",
      "g_messenger",
      "g_trigger_notif_msg",
      "g_user",
      "g_has_message",
      "g_student_list",
      "g_roles",
      "g_admin_list",
      "g_employee_list",
      "g_chat_bulk_id"
    ]),
    breakpoint() {
      return this.$vuetify.breakpoint;
    },
    listPerson() {
      let list = [];
      if (this.tab == 0) {
        list = this.g_admin_list;
      } else if (this.tab == 1) {
        list = this.g_employee_list;
      }
      return list;
    },
    showMessengerList() {
      return !this.g_messenger.selected_user && this.g_chat_bulk_id.length == 0;
    },
    showMessengerChatBox() {
      return this.g_messenger.selected_user || this.g_chat_bulk_id.length > 0;
    }
  },
  async created() {
    if (this.g_roles.includes(4)) this.tab = 1;
    else this.tab = 0;

    store.dispatch("getAdminListChat", { search: "" }).then(res => {
      if (res) {
        store.dispatch("checkHasMessage");
        if (
          this.$route.query.user &&
          this.$route.query.type == "admin" &&
          this.tab == 0
        ) {
          const getUser = this.listPerson.find(
            r => (r.person = this.$route.query.user)
          );
          store.commit("SET_SELECTED_USER", getUser);
          this.getChat();
        }
      }
    });
    store.dispatch("getEmployeeListChat", { search: "" }).then(res => {
      if (res) {
        store.dispatch("checkHasMessage");
        if (
          this.$route.query.user &&
          this.$route.query.type == "teacher" &&
          this.tab == 1
        ) {
          const getUser = this.listPerson.find(
            r => (r.person = this.$route.query.user)
          );
          store.commit("SET_SELECTED_USER", getUser);
          this.getChat();
        }
      }
    });
    store.dispatch("getStudentListChat", { search: "" }).then(res => {
      if (res) {
        store.dispatch("checkHasMessage");
        if (
          this.$route.query.user &&
          this.$route.query.type == "student" &&
          this.tab == 2
        ) {
          const getUser = this.g_student_list.find(r =>
            r.students.map(s => (s.person = this.$route.query.user))
          );
          store.commit("SET_SELECTED_USER", getUser);
          this.getChat();
        }
      }
    });
  },
  destroyed() {
    store.commit("SET_SELECTED_USER", null);
  },
  methods: {
    tabChange() {
      store.commit("SET_CHAT_TYPE", null);
      this.g_messenger.chat_list = [];
      this.g_messenger.selected_user = null;
      this.groupChatId = [];
    },
    async getChat(param) {
      if (this.g_messenger.selected_user) {
        this.loadingChat = true;
        const getChat = await store.dispatch("getPersonalListChat", param);
        if (getChat) this.loadingChat = false;
        document.getElementById("chatElement").scrollTop += 25;
        if (!param) {
          setTimeout(() => {
            document.getElementById("chatElement").scrollTop += 10000;
          }, 200);
        }
      }
    }
  }
};
</script>

<style lang="scss">
#messenger {
  position: relative;
  .v-expansion-panel-content__wrap {
    padding: 0 !important;
  }
  .desktop-view {
    height: calc(100vh - 72px);
  }
}
</style>
