<template>
  <div
    :class="
      isMobile ? 'messenger-chatbox-wrapper-mob' : 'messenger-chatbox-wrapper'
    "
  >
    <div>
      <div class="pa-3 grey lighten-4 d-flex font-weight-bold">
        <v-icon v-if="isMobile" color="primary" @click="removeSelectedUser">
          mdi-arrow-left
        </v-icon>
        <v-spacer></v-spacer>
        <span v-if="g_messenger.selected_user && tab == 2">
          <v-avatar
            v-if="g_messenger.selected_user.photo"
            size="35"
            class="mr-2"
          >
            <v-img
              :lazy-src="g_messenger.selected_user.photo"
              :src="g_messenger.selected_user.photo"
            ></v-img>
          </v-avatar>
          <span v-else>
            <v-avatar size="35">
              <v-img
                v-if="g_messenger.selected_user.gender == 'L'"
                :src="boyPhoto"
              ></v-img>
              <v-img
                v-else-if="g_messenger.selected_user.gender == 'P'"
                :src="girlPhoto"
              ></v-img>
              <v-img v-else :src="boyPhoto"></v-img>
            </v-avatar>
          </span>
          <span class="pl-1">{{ g_messenger.selected_user.name }}</span>
        </span>
        <span v-if="g_messenger.selected_user && tab != 2">
          <v-avatar
            v-if="g_messenger.selected_user.photo"
            size="35"
            class="mr-2"
          >
            <v-img
              :lazy-src="g_messenger.selected_user.photo"
              :src="g_messenger.selected_user.photo"
            />
          </v-avatar>
          <span v-else>
            <v-avatar size="35">
              <v-img
                v-if="g_messenger.selected_user.gender == 'L'"
                :src="manPhoto"
              />
              <v-img
                v-else-if="g_messenger.selected_user.gender == 'P'"
                :src="womanPhoto"
              />
              <v-img v-else :src="boyPhoto" />
            </v-avatar>
          </span>
          <span class="pl-1">{{ g_messenger.selected_user.name }}</span>
        </span>
        <span
          v-else-if="!g_messenger.selected_user && g_chat_bulk_id.length > 0"
          style="padding: 6px 0"
        >
          {{ $t("app.mass_message") }}
        </span>
        <span
          v-else-if="!g_messenger.selected_user && g_chat_bulk_id.length == 0"
        >
          {{ $t("app.select_contact") }}
        </span>
        <v-spacer />
      </div>
    </div>

    <v-card
      id="chatElement"
      v-scroll.self="onScroll"
      flat
      class="overflow-y-auto text-center chat-element"
      :class="g_darkmode ? '' : 'grey lighten-5'"
    >
      <v-progress-linear
        v-if="loadingGetChat"
        color="primary"
        indeterminate
        rounded
        height="4"
      />
      <v-card-text class="text-center">
        <v-alert
          v-if="
            g_messenger.selected_user &&
              g_messenger.total != g_messenger.lastPage &&
              g_messenger.total != 0
          "
          dense
          class="grey lighten-5 mb-0 caption"
        >
          {{ $t("app.get_chat") }}
        </v-alert>
        <span v-for="(c, i) in g_messenger.chat_list" :key="c.id">
          <div v-if="showDate(c, i)" class="d-flex align-center mt-2">
            <v-divider></v-divider>
            <v-chip class="mx-2">
              {{ c.created_at | dateFormat }}
            </v-chip>
            <v-divider></v-divider>
          </div>
          <span>
            <v-row v-if="c.from.person == g_user_id" class="mt-1">
              <v-row
                no-gutters
                style="word-break: break-all"
                justify="end"
                class="mr-2"
                align="center"
              >
                <v-card
                  style="max-width: 60%"
                  class="pa-2 elevation-1 primary white--text"
                >
                  <div
                    class="d-flex flex-wrap align-center"
                    style="word-break: break-word; text-align: left"
                  >
                    <div style="font-size: 1.2em" class="mr-2">
                      <span v-html="linkify(c.message, false)" />
                    </div>
                    <v-spacer />
                    <div class=" caption d-flex">
                      {{ c.created_at | timeFormat }}
                    </div>
                  </div>
                </v-card>
              </v-row>
              <!-- <v-avatar v-show="c.to.photo" size="40">
                <v-img :src="c.to.photo"></v-img>
              </v-avatar> -->
            </v-row>
            <v-row v-else class="mt-1">
              <!-- <v-avatar v-show="c.from.photo" size="40" class="mr-2">
                <v-img :src="c.from.photo"></v-img>
              </v-avatar> -->
              <v-row
                no-gutters
                style="word-break:break-all"
                justify="start"
                align="center"
              >
                <v-card style="max-width: 60%" class="pa-2 elevation-1">
                  <div
                    class="d-flex flex-wrap align-center"
                    style="word-break: break-word; text-align: left"
                  >
                    <div class="mr-2">
                      <span v-html="linkify(c.message, true)" />
                    </div>
                    <v-spacer />
                    <div class="grey--text caption">
                      {{ c.created_at | timeFormat }}
                    </div>
                  </div>
                </v-card>
              </v-row>
            </v-row>
          </span>
        </span>
      </v-card-text>
    </v-card>

    <v-card
      v-if="
        g_user.role_type !== 'STUDENT' ||
          (g_user.role_type === 'STUDENT' && tab !== 0)
      "
      class="pa-2"
      style="position: absolute; bottom: 0; left: 0; width: 100%"
      flat
    >
      <v-row no-gutters>
        <v-menu :close-on-content-click="false" :nudge-width="200" top offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="mr-2"
              :disabled="
                !g_messenger.selected_user && g_chat_bulk_id.length == 0
              "
            >
              <v-icon>mdi-emoticon</v-icon>
            </v-btn>
          </template>
          <VEmojiPicker class="mr-2" @select="selectEmoji" />
        </v-menu>

        <v-text-field
          v-if="g_messenger.selected_user || g_chat_bulk_id.length != 0"
          v-model="messageVal"
          :loading="loadingChat"
          :class="g_darkmode ? '' : 'white'"
          :placeholder="$t('app.write_a_message')"
          :readonly="loadingChat"
          outlined
          class="rounded-pill"
          hide-details
          dense
          append-icon="mdi-send"
          autocomplete="off"
          @click:append="sendMessage"
          @keyup.enter="sendMessage"
        />
      </v-row>
    </v-card>
  </div>
</template>

<script>
import { VEmojiPicker } from "v-emoji-picker";
import { getAppDomain } from "@/utils/storage";

import {
  sendPersonal,
  sendClassGroup,
  sendTeacherGroup
} from "@/api/messenger";
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment/moment";

let typingTimer;
const doneTypingInterval = 1000;

export default {
  components: {
    VEmojiPicker
  },
  filters: {
    dateFormat: value => {
      if (!value) return "";
      if (value == moment()) {
        return "Today";
      } else {
        return moment(value).format("DD MMMM YYYY");
      }
    },
    timeFormat: value => {
      if (!value) return "";
      if (value == moment()) {
        return "Today";
      } else {
        return moment(value).format("HH:mm");
      }
    }
  },
  props: {
    getChat: Function,
    loadingGetChat: Boolean,
    tab: Number
  },
  data() {
    return {
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      manPhoto: require("@/assets/svg/employee_man.svg"),
      womanPhoto: require("@/assets/svg/employee_woman.svg"),
      messageVal: "",
      searchValue: "",
      openPanelStudent: null,
      listUserStudent: [],
      loadingChat: false,
      loadingSearch: false,
      loadChat: false,
      getType: ""
    };
  },
  watch: {
    "g_messenger.chat_list"(newChat) {
      if (
        newChat &&
        !this.loadChat &&
        this.$route.name == "Messenger" &&
        this.g_messenger.selected_user
      ) {
        if (!this.getType) {
          this.scrollDown();
        } else {
          this.scrollDown("min");
        }
      }
    }
  },
  computed: {
    ...mapGetters([
      "g_user",
      "g_user_id",
      "g_darkmode",
      "g_messenger",
      "g_roles",
      "g_trigger_notif_msg",
      "g_has_message",
      "g_chat_type",
      "g_chat_bulk_id",
      "g_school",
      "g_language"
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly || this.$vuetify.breakpoint.smOnly;
    }
  },
  created() {
    moment.locale(this.g_language);
  },
  methods: {
    showDate(c, i) {
      const getDate = moment(c.created_at).format("DDMMYY");

      if (i === 0) {
        return true;
      } else if (
        i > 0 &&
        moment(this.g_messenger.chat_list[i - 1].created_at).format(
          "DDMMYY"
        ) !== getDate
      ) {
        return true;
      } else return false;
    },
    linkify(text, senderChat) {
      const aLink = [];
      const linksFound = text.match(/(?:www|https?)[^\s]+/g);

      if (linksFound != null) {
        for (let i = 0; i < linksFound.length; i++) {
          let replace = linksFound[i];

          if (!linksFound[i].match(/(http(s?)):\/\//)) {
            replace = "http://" + linksFound[i];
          }

          aLink.push(
            `<a style="color: ${
              senderChat ? "black" : "white"
            } !important;" href="` +
              replace +
              '" target="_blank">' +
              replace +
              "</a>"
          );

          text = text
            .split(linksFound[i])
            .map(item => {
              return aLink[i].includes("iframe") ? item.trim() : item;
            })
            .join(aLink[i]);
        }
        return text;
      } else {
        return text;
      }
    },
    async removeSelectedUser() {
      this.getChat();
      this.getType = "";
      store.commit("REMOVE_SELECTED_USER");
      store.commit("SET_CHAT_BULK_ID", []);
    },
    scrollDown(min) {
      if (min) {
        setTimeout(() => {
          document.getElementById("chatElement").scrollTop += 75;
        }, 100);
      } else {
        setTimeout(() => {
          document.getElementById("chatElement").scrollTop += 10000;
        }, 100);
      }
    },
    checkStudentHasMessage(data) {
      const getUnreadMessage = [];
      data.students.map(s => {
        if (s.unread_messages > 0) getUnreadMessage.push(s.unread_messages);
      });
      if (getUnreadMessage.length) return true;
      else return false;
    },
    onScroll(e) {
      if (
        e.target.scrollTop == 0 &&
        this.g_messenger.total != this.g_messenger.lastPage &&
        this.g_messenger.total != 0
      ) {
        this.getChat("load");
        this.getType = "load";
        this.loadChat = true;
        setTimeout(() => {
          this.loadChat = false;
        }, 500);
      }
    },
    selectEmoji(emoji) {
      this.messageVal = this.messageVal + emoji.data;
    },
    sendMessage() {
      if (this.loadingChat == false) {
        this.messageVal.trim();
        this.loadingChat = true;
        switch (this.g_chat_type) {
          case "student":
          case "student_class":
            sendClassGroup({
              from: this.g_user_id,
              class: this.g_chat_bulk_id,
              message: this.messageVal
            })
              .then(res => {
                if (res.data.code) {
                  this.$socket.emit("serverMessage", {
                    recipient: `${getAppDomain()}_${
                      this.$store.getters.g_school.profile.institution
                    }_STUDENT`.toUpperCase(),
                    data: res.data.data
                  });
                  this.messageVal = "";
                  store.commit("SET_CHAT_BULK_ID", []);
                  store.commit("SET_CHAT_TYPE", null);
                  store.commit("CALL_SNACKBAR", {
                    msg: this.$i18n.t("app.successfully_sending_bulk_messages"),
                    color: "success"
                  });
                  this.scrollDown();
                } else {
                  store.commit("CALL_SNACKBAR", {
                    msg: res.data.message,
                    color: "error"
                  });
                }
                this.loadingChat = false;
              })
              .catch(() => (this.loadingChat = false));
            break;
          case "teacher":
            sendTeacherGroup({
              from: this.g_user_id,
              message: this.messageVal
            })
              .then(res => {
                if (res.data.code) {
                  this.$socket.emit("serverMessage", {
                    recipient: res.data.recipient,
                    data: res.data.data
                  });
                  this.messageVal = "";
                  store.commit("SET_CHAT_BULK_ID", []);
                  store.commit("SET_CHAT_TYPE", null);
                  store.commit("CALL_SNACKBAR", {
                    msg: this.$i18n.t("app.successfully_sending_bulk_messages"),
                    color: "success"
                  });
                  this.scrollDown();
                } else {
                  store.commit("CALL_SNACKBAR", {
                    msg: res.data.message,
                    color: "error"
                  });
                }
                this.loadingChat = false;
              })
              .catch(() => (this.loadingChat = false));
            break;
          default:
            sendPersonal({
              from: this.g_user_id,
              to: this.g_messenger.selected_user.person,
              message: this.messageVal
            })
              .then(res => {
                if (res.data.code) {
                  this.$socket.emit("serverMessage", {
                    recipient: `${getAppDomain()}_${
                      this.g_messenger.selected_user.person
                    }`.toUpperCase(),
                    data: res.data.data
                  });

                  const u = this.g_user;
                  const su = this.g_messenger.selected_user;
                  const fromData = {
                    created_at: moment(),
                    from: {
                      email: u.email,
                      gender: u.gender,
                      name: u.fullname,
                      person: this.g_user_id,
                      photo: u.photo
                    },
                    have_seen: "FALSE",
                    message: this.messageVal,
                    to: {
                      email: "",
                      gender: su.gender,
                      name: su.name,
                      person: su.person,
                      photo: su.photo
                    }
                  };
                  store.commit("SET_CHAT_LIST", fromData);
                  this.$vuetify.goTo(1000);
                  setTimeout(() => {
                    this.messageVal = "";
                  }, 100);
                  this.scrollDown();
                } else {
                  store.commit("CALL_SNACKBAR", {
                    msg: res.data.message,
                    color: "error"
                  });
                }
                this.loadingChat = false;
              })
              .catch(() => (this.loadingChat = false));
            break;
        }
      } else {
        this.messageVal = "";
      }
    },
    searchHandler() {
      this.loadingSearch = true;
      switch (this.tab) {
        case 2:
          clearTimeout(typingTimer);
          typingTimer = setTimeout(async () => {
            await store.dispatch("getStudentListChat", {
              search: this.searchValue
            });
            this.loadingSearch = false;
          }, doneTypingInterval);

          break;
        case 1:
          clearTimeout(typingTimer);
          typingTimer = setTimeout(async () => {
            await store.dispatch("getEmployeeListChat", {
              search: this.searchValue
            });
            this.loadingSearch = false;
          }, doneTypingInterval);
          break;
        default:
          clearTimeout(typingTimer);
          typingTimer = setTimeout(async () => {
            await store.dispatch("getAdminListChat", {
              search: this.searchValue
            });
            this.loadingSearch = false;
          }, doneTypingInterval);
          break;
      }
    }
  }
};
</script>

<style lang="scss">
.messenger-chatbox-wrapper-mob {
  position: relative;
  height: calc(100vh - 64px);
}
.messenger-chatbox-wrapper {
  position: relative;
  height: calc(100vh - 120px);
}

.list-custom-padding {
  .v-list-item {
    min-height: 50px !important;
  }
}

.chat-element {
  height: calc(100% - 120px);
  background: rgb(241, 241, 241);
}

.separator {
  display: flex;
  align-items: center;
  text-align: center;
  color: lightgray;
}
.separator::before,
.separator::after {
  content: "";
  flex: 1;
  border-bottom: 1px solid lightgray;
}
.separator::before {
  margin-right: 0.5em;
}
.separator::after {
  margin-left: 0.5em;
}
</style>
