<template>
  <div>
    <v-sheet id="messenger-list-wrapper">
      <v-text-field
        v-model="searchValue"
        :loading="loadingSearch"
        class="my-3 mx-3"
        append-icon="mdi-magnify"
        outlined
        dense
        clearable
        hide-details
        @keyup="searchHandler()"
        @click:clear="searchHandler()"
      ></v-text-field>

      <v-list class="body-2 pa-0">
        <v-list-item-group color="primary">
          <!-- teacher -->
          <v-list-item
            v-if="tab == 1"
            :class="g_chat_type == 'teacher' ? 'primary white--text' : ''"
            @click="setAction('teacher')"
          >
            {{ $t("app.mass_message_teacher") }}
            <v-icon color="primary" class="ml-2" small
              >mdi-chevron-right</v-icon
            >
          </v-list-item>
          <!-- student -->
          <v-list-item
            v-if="tab == 2"
            :class="g_chat_type == 'student' ? 'primary white--text' : ''"
            @click="setAction('student')"
          >
            {{ $t("app.mass_message_student") }}
            <v-icon color="primary" class="ml-2" small
              >mdi-chevron-right</v-icon
            >
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <v-list v-if="tab != 2" three-line class="list-custom-padding py-0">
        <template v-for="(item, index) in listUser">
          <v-list-item
            :style="
              g_messenger.selected_user
                ? g_messenger.selected_user.person == item.person
                  ? 'background: #87dafc;'
                  : ''
                : ''
            "
            :key="index"
            @click="setChat(item, index)"
          >
            <v-badge
              v-if="item.unread_messages > 0"
              color="primary"
              bordered
              overlap
              :content="item.unread_messages"
            >
              <v-avatar v-if="item.photo" size="35">
                <v-img :src="item.photo"></v-img>
              </v-avatar>
              <span v-else>
                <v-avatar size="35">
                  <v-img v-if="item.gender == 'L'" :src="manPhoto"></v-img>
                  <v-img
                    v-else-if="item.gender == 'P'"
                    :src="womanPhoto"
                  ></v-img>
                  <v-img v-else :src="manPhoto"></v-img>
                </v-avatar>
              </span>
            </v-badge>
            <span v-else>
              <v-avatar v-if="item.photo" size="35">
                <v-img :src="item.photo"></v-img>
              </v-avatar>
              <span v-else>
                <v-avatar size="35">
                  <v-img v-if="item.gender == 'L'" :src="manPhoto"></v-img>
                  <v-img
                    v-else-if="item.gender == 'P'"
                    :src="womanPhoto"
                  ></v-img>
                  <v-img v-else :src="manPhoto"></v-img>
                </v-avatar>
              </span>
            </span>
            <v-list-item-content class="ml-4">
              <v-list-item-title class="body-1 font-weight-bold">
                {{ item.name }}
              </v-list-item-title>
              <v-list-item-subtitle
                class="caption"
                v-html="item.nip"
              ></v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list>
      <v-expansion-panels
        v-if="tab == 2"
        v-model="openPanelStudent"
        accordion
        focusable
        flat
        class="elevation-0"
      >
        <v-expansion-panel
          v-for="(r, i) in g_student_list"
          :key="i"
          class="grey lighten-5"
        >
          <v-expansion-panel-header>
            <v-badge v-if="checkStudentHasMessage(r)" dot left color="primary">
              <strong>{{ r.name }}</strong>
            </v-badge>
            <strong v-else>{{ r.name }}</strong>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="pa-0">
            <v-list-item
              class="font-weight-bold body-2 pa-0 px-4"
              :class="listMassClass(r.id)"
              @click="setAction('student_class', r.id)"
            >
              {{ $t("app.mass_message_student_class", { class: r.name }) }}
            </v-list-item>
            <v-list three-line class="list-custom-padding ">
              <template v-for="(item, index) in r.students">
                <v-list-item
                  :style="
                    g_messenger.selected_user
                      ? g_messenger.selected_user.person == item.person
                        ? 'background: #87dafc;'
                        : ''
                      : ''
                  "
                  :key="index"
                  @click="setChat(item, index, i)"
                >
                  <v-badge
                    v-if="item.unread_messages > 0"
                    color="primary"
                    bordered
                    overlap
                    :content="item.unread_messages"
                  >
                    <v-avatar v-if="item.photo" size="35">
                      <v-img :src="item.photo"></v-img>
                    </v-avatar>
                    <span v-else>
                      <v-avatar size="35">
                        <v-img
                          v-if="item.gender == 'L'"
                          :src="boyPhoto"
                        ></v-img>
                        <v-img
                          v-else-if="item.gender == 'P'"
                          :src="girlPhoto"
                        ></v-img>
                        <v-img v-else :src="boyPhoto"></v-img>
                      </v-avatar>
                    </span>
                  </v-badge>
                  <span v-else>
                    <v-avatar v-if="item.photo" size="35">
                      <v-img :src="item.photo"></v-img>
                    </v-avatar>
                    <span v-else>
                      <v-avatar size="35">
                        <v-img
                          v-if="item.gender == 'L'"
                          :src="boyPhoto"
                        ></v-img>
                        <v-img
                          v-else-if="item.gender == 'P'"
                          :src="girlPhoto"
                        ></v-img>
                        <v-img v-else :src="boyPhoto"></v-img>
                      </v-avatar>
                    </span>
                  </span>
                  <v-list-item-content class="ml-4">
                    <v-list-item-title
                      class="body-1 font-weight-bold"
                      v-html="item.name"
                    ></v-list-item-title>
                    <v-list-item-subtitle
                      class="caption"
                      v-html="item.nis"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-sheet>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import store from "@/store";
import moment from "moment/moment";
moment.locale("id");

let typingTimer;
const doneTypingInterval = 1000;

export default {
  filters: {
    dateFormat: value => {
      if (!value) return "";
      if (value == moment()) {
        return "Today";
      } else {
        return moment(value).format("DD MMM YYYY");
      }
    },
    timeFormat: value => {
      if (!value) return "";
      if (value == moment()) {
        return "Today";
      } else {
        return moment(value).format("HH:mm");
      }
    }
  },
  props: {
    tab: Number,
    getChat: Function
  },
  data() {
    return {
      boyPhoto: require("@/assets/svg/boy.svg"),
      girlPhoto: require("@/assets/svg/girl.svg"),
      manPhoto: require("@/assets/svg/employee_man.svg"),
      womanPhoto: require("@/assets/svg/employee_woman.svg"),
      messageVal: "",
      searchValue: "",
      openPanelStudent: null,
      listUserStudent: [],
      loadingChat: false,
      loadingSearch: false
    };
  },
  computed: {
    ...mapGetters([
      "g_user",
      "g_user_id",
      "g_darkmode",
      "g_messenger",
      "g_admin_list",
      "g_employee_list",
      "g_student_list",
      "g_roles",
      "g_trigger_notif_msg",
      "g_has_message",
      "g_chat_type",
      "g_chat_bulk_id"
    ]),
    listUser() {
      let list = [];
      if (this.tab == 0) {
        list = this.g_admin_list;
      } else if (this.tab == 1) {
        list = this.g_employee_list;
      }
      return list;
    }
  },
  methods: {
    listMassClass(id) {
      return this.g_chat_type == "student_class" &&
        this.g_chat_bulk_id.filter(r => r == id).length > 0
        ? "primary white--text"
        : "";
    },
    checkStudentHasMessage(data) {
      const getUnreadMessage = [];
      data.students.map(s => {
        if (s.unread_messages > 0) getUnreadMessage.push(s.unread_messages);
      });
      if (getUnreadMessage.length) return true;
      else return false;
    },
    searchHandler() {
      this.loadingSearch = true;
      switch (this.tab) {
        case 2:
          clearTimeout(typingTimer);
          typingTimer = setTimeout(async () => {
            await store.dispatch("getStudentListChat", {
              search: this.searchValue
            });
            this.loadingSearch = false;
          }, doneTypingInterval);

          break;
        case 1:
          clearTimeout(typingTimer);
          typingTimer = setTimeout(async () => {
            await store.dispatch("getEmployeeListChat", {
              search: this.searchValue
            });
            this.loadingSearch = false;
          }, doneTypingInterval);
          break;
        default:
          clearTimeout(typingTimer);
          typingTimer = setTimeout(async () => {
            await store.dispatch("getAdminListChat", {
              search: this.searchValue
            });
            this.loadingSearch = false;
          }, doneTypingInterval);
          break;
      }
    },
    async setAction(type, idClass) {
      this.g_messenger.selected_user = null;
      this.g_messenger.chat_list = [];
      store.commit("SET_CHAT_TYPE", type);
      store.commit("SET_CHAT_TYPE", type);
      if (type == "student" || type == "student_class") {
        if (idClass) {
          store.commit("SET_CHAT_BULK_ID", [idClass]);
        } else {
          const classList = [];
          for (const i in this.g_student_list) {
            await classList.push(this.g_student_list[i]);
          }
          const getId = await classList.map(r => r.id);
          store.commit("SET_CHAT_BULK_ID", getId);
        }
      } else {
        store.commit("SET_CHAT_BULK_ID", ["teacher"]);
      }
    },
    async setChat(user, idx, studentIdx) {
      store.commit("SET_CHAT_TYPE", null);
      let type = "student";
      if (user.type) type = user.type.toLowerCase();

      if (idx != undefined) {
        if (type === "admin") {
          store.commit(
            "SUBTRACTION_UNREAD_MESSAGE",
            this.g_admin_list[idx].unread_messages
          );
          setTimeout(() => {
            this.g_admin_list[idx].unread_messages = 0;
          }, 200);
        }
        if (type === "teacher") {
          store.commit(
            "SUBTRACTION_UNREAD_MESSAGE",
            this.g_employee_list[idx].unread_messages
          );
          setTimeout(() => {
            this.g_employee_list[idx].unread_messages = 0;
          }, 200);
        }
        if (type === "student") {
          store.commit(
            "SUBTRACTION_UNREAD_MESSAGE",
            this.g_student_list[studentIdx].students[idx].unread_messages
          );
          setTimeout(() => {
            this.g_student_list[studentIdx].students[idx].unread_messages = 0;
          }, 200);
        }

        setTimeout(() => {
          store.dispatch("checkHasMessage");
        }, 200);
      }

      if (!this.$route.query.user && this.$route.query.user != user.id) {
        this.$router.replace({
          path: "/messenger",
          query: { user: user.person, type: type, tab: this.tab }
        });
      }

      await store.commit("SET_SELECTED_USER", user);
      store.commit("RESET_TOTAL_CHAT", this.$vuetify.breakpoint.smOnly);
      this.getChat();
    }
  }
};
</script>

<style lang="scss">
#messenger-list-wrapper {
  height: calc(100vh - 120px);
  overflow: auto;
  .list-custom-padding {
    .v-list-item {
      min-height: 50px !important;
    }
  }

  .separator {
    display: flex;
    align-items: center;
    text-align: center;
    color: lightgray;
  }
  .separator::before,
  .separator::after {
    content: "";
    flex: 1;
    border-bottom: 1px solid lightgray;
  }
  .separator::before {
    margin-right: 0.5em;
  }
  .separator::after {
    margin-left: 0.5em;
  }
}
</style>
